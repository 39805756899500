import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  Autocomplete,
  Stack,
  Divider,
  Tab,
  Tabs,
  Tooltip,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TodayIcon from '@mui/icons-material/Today';
import Checkbox from '@mui/material/Checkbox';
import { getToken, getFarmId } from '../../auth/auth';
import { BASE_URL } from '../../Constants';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom';

const ORDER_FORMATS = [
  { value: 'weight', label: 'Weight (lbs)' },
  { value: 'wholesale_case', label: 'Wholesale Case (2 lbs)' },
  { value: 'retail_case', label: 'Retail (400g)' },
  { value: 'retail_case_100', label: 'Retail (100g)' },
  { value: 'retail_case_600', label: 'Retail Lion\'s Mane (150g)' },
  { value: 'mix_case', label: 'Mix Case' }
];

// Add this mapping constant at the top of the file with other constants
const STRAIN_NAME_MAPPING = {
  'Black Pearl': 'Black King',
  // Add other mappings as needed
};

function CustomerOrders() {
  const [inventoryData, setInventoryData] = useState([]);
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [selectedOrderFarm, setSelectedOrderFarm] = useState(''); // New state for order farm
  const [strains, setStrains] = useState(new Set());
  const [processedData, setProcessedData] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedStrain, setSelectedStrain] = useState(null);
  const [orderWeight, setOrderWeight] = useState('');
  const [orderDate, setOrderDate] = useState(new Date());
  const [fulfillmentDate, setFulfillmentDate] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [orders, setOrders] = useState([]);
  const [editingOrder, setEditingOrder] = useState(null);
  const [editedOrder, setEditedOrder] = useState({});
  const [orderItems, setOrderItems] = useState([{ strain_id: '', weight: '', order_format: 'weight', case_quantity: '' }]);
  const [editedOrderItems, setEditedOrderItems] = useState([]);
  const [weeklyOrders, setWeeklyOrders] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openShrinkageDialog, setOpenShrinkageDialog] = useState(false);
  const [shrinkageData, setShrinkageData] = useState([]);
  const token = getToken();
  const [editMode, setEditMode] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [completedItems, setCompletedItems] = useState({});
  const [orderEaseProducts, setOrderEaseProducts] = useState(null);
  const [openOrderEaseDialog, setOpenOrderEaseDialog] = useState(false);
  const [selectedOrderEaseItems, setSelectedOrderEaseItems] = useState([]);
  const [poNumber, setPoNumber] = useState('');
  const [loadingOrderId, setLoadingOrderId] = useState(null);
  const navigate = useNavigate();
  const [poUrl, setPoUrl] = useState('');

  useEffect(() => {
    // Get the user's farm ID from the JWT token
    const userFarmId = getFarmId();
    
    fetchFarms().then(() => {
      // If user has a farm ID, preselect it but allow changes
      if (userFarmId) {
        setSelectedFarm(userFarmId);
        setSelectedOrderFarm(userFarmId); // Set initial order farm
      } else {
        // Otherwise fallback to Ottawa farm
        const ottawaFarm = farms.find(farm => farm.farm_name.toLowerCase() === 'ottawa');
        if (ottawaFarm) {
          setSelectedFarm(ottawaFarm.farm_id);
          setSelectedOrderFarm(ottawaFarm.farm_id);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (selectedFarm) {
      fetchInventoryData();
    }
  }, [selectedFarm]);

  useEffect(() => {
    // Process inventory data to get unique strains and organize data by farm
    const uniqueStrains = new Set();
    const farmData = {};

    inventoryData.forEach(item => {
      uniqueStrains.add({
        strain_id: item.strain_id,
        strain_name: item.strain_name
      });
      
      if (!farmData[item.farm_id]) {
        farmData[item.farm_id] = {
          farm_id: item.farm_id,
          farm_name: item.farm_name,
          strains: {}
        };
      }
      
      farmData[item.farm_id].strains[item.strain_name] = item.current_weight;
    });

    setStrains(uniqueStrains);
    setProcessedData(Object.values(farmData));
  }, [inventoryData]);

  useEffect(() => {
    fetchCustomers();
    fetchOrders();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [currentDate]);

  const fetchFarms = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/readfarms`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFarms(data);
    } catch (error) {
      console.error('Error fetching farms:', error);
    }
  };

  const fetchInventoryData = async () => {
    try {
      let url = `${BASE_URL}/api/inventory`;
      if (selectedFarm) {
        url += `?farmId=${selectedFarm}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setInventoryData(data);
    } catch (error) {
      console.error('Error fetching inventory data:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/readcustomers`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) throw new Error('Failed to fetch customers');
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/orders?date=${currentDate.toISOString()}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch orders');
      const data = await response.json();
      setWeeklyOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  // Separate handlers for inventory and order farm changes
  const handleInventoryFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleOrderFarmChange = (event) => {
    setSelectedOrderFarm(event.target.value);
  };

  const handleEdit = (farmId) => {
    setEditingRow(farmId);
    const farmData = processedData.find(row => row.farm_id === farmId);
    
    // Create a mapping of strain_id to weight from the current data
    const initialWeights = {};
    Array.from(strains).forEach(strain => {
      initialWeights[strain.strain_id] = farmData.strains[strain.strain_name] || 0;
    });
    
    setEditedData(initialWeights);
    setHasChanges(false);
  };

  const handleCancelEdit = () => {
    setEditingRow(null);
    setEditedData({});
    setHasChanges(false);
  };

  const handleWeightChange = (strain, value) => {
    // Convert value to number, default to 0 if empty
    const numericValue = value === '' ? 0 : Number(value);
    
    // Only update if it's a valid number
    if (!isNaN(numericValue)) {
      setEditedData(prev => ({
        ...prev,
        [strain.strain_id]: numericValue
      }));
      setHasChanges(true);
    }
  };

  const handleSave = async (farmId) => {
    try {
      // Only include strains that have been edited
      const updates = Array.from(strains)
        .filter(strain => strain.strain_id in editedData)
        .map(strain => ({
          farm_id: Number(farmId),
          strain_id: Number(strain.strain_id),
          current_weight: Number(editedData[strain.strain_id])
        }));

      // Don't send request if no updates
      if (updates.length === 0) {
        setEditingRow(null);
        setEditedData({});
        setHasChanges(false);
        return;
      }

      const response = await fetch(`${BASE_URL}/api/inventory/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ updates })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update inventory');
      }

      await fetchInventoryData();
      setEditingRow(null);
      setEditedData({});
      setHasChanges(false);
    } catch (error) {
      console.error('Error updating inventory:', error);
      // You might want to show this error to the user through a notification system
      throw error; // Re-throw to be handled by the caller if needed
    }
  };

  const handleEditOrder = (order) => {
    setEditMode(true);
    setSelectedCustomer(customers.find(c => c.customer_id === order.customer_id));
    setSelectedOrderFarm(order.farm_id);
    setFulfillmentDate(order.fulfillment_date ? new Date(order.fulfillment_date) : null);
    setPoUrl(order.invoice_url || '');

    const formattedItems = order.items.map(item => ({
      strain_id: item.strain_id !== null ? item.strain_id.toString() : '',
      weight: item.weight !== null ? item.weight.toString() : '',
      order_format: item.order_format || 'weight',
      case_quantity: item.case_quantity !== null ? item.case_quantity.toString() : ''
    }));

    console.log('Editing order:', { order, poUrl: order.invoice_url });
    
    setOrderItems(formattedItems);
    setSelectedOrder(order);
    setOpenOrderDialog(true);
  };

  const handleCloseOrderDialog = () => {
    setOpenOrderDialog(false);
    setSelectedCustomer(null);
    setOrderItems([{ strain_id: '', weight: '', order_format: 'weight', case_quantity: '' }]);
    setFulfillmentDate(null);
    setEditMode(false);
    setSelectedOrder(null);
    setPoUrl('');
  };

  // Update the order dialog to use selectedOrderFarm
  const handleCreateOrder = async () => {
    try {
      // Filter out invalid/empty order items before sending
      const validOrderItems = orderItems.filter(item => {
        // For mix case, only need case_quantity
        if (item.order_format === 'mix_case') {
          return item.case_quantity && parseInt(item.case_quantity) > 0;
        }
        
        // For weight format
        if (item.order_format === 'weight') {
          return item.strain_id && item.weight && parseFloat(item.weight) > 0;
        }
        
        // For all case formats (wholesale, retail, retail_600)
        return item.strain_id && item.case_quantity && parseInt(item.case_quantity) > 0;
      });

      if (validOrderItems.length === 0) {
        throw new Error('Please add at least one valid order item');
      }

      // Format dates properly with a space between date and time
      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        return d.toISOString().slice(0, 19).replace('T', ' ');
      };

      const orderData = {
        customer_id: selectedCustomer.customer_id,
        farm_id: selectedOrderFarm,
        order_items: validOrderItems.map(item => ({
          strain_id: item.strain_id ? parseInt(item.strain_id) : null,
          order_format: item.order_format,
          case_quantity: item.case_quantity ? parseInt(item.case_quantity) : null,
          weight: item.weight ? parseFloat(item.weight) : null
        })),
        order_date: formatDate(new Date()),
        fulfillment_date: formatDate(fulfillmentDate),
        po_url: poUrl || null
      };

      console.log('Sending order data:', orderData); // Debug log

      const response = await fetch(`${BASE_URL}/api/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify(orderData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create order');
      }

      await Promise.all([fetchOrders(), fetchInventoryData()]);
      handleCloseOrderDialog();
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Failed to create order: ' + error.message);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    if (!window.confirm('Are you sure you want to delete this order?')) {
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/orders/${orderId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete order');
      }

      // Update the local state to remove the deleted order
      setWeeklyOrders(prevOrders => prevOrders.filter(order => order.order_id !== orderId));

      // Optionally refresh inventory data if needed
      await fetchInventoryData();

    } catch (error) {
      console.error('Error deleting order:', error);
      alert('Failed to delete order: ' + error.message);
    }
  };

  const handleAddOrderItem = () => {
    setOrderItems([...orderItems, { strain_id: '', weight: '', order_format: 'weight', case_quantity: '' }]);
  };

  const handleRemoveOrderItem = (index) => {
    if (orderItems.length > 1) {
      setOrderItems(orderItems.filter((_, i) => i !== index));
    }
  };

  const handleOrderItemChange = (index, field, value) => {
    const newItems = [...orderItems];
    newItems[index] = {
      ...newItems[index],
      [field]: value
    };

    // Handle format changes
    if (field === 'order_format') {
      if (value === 'mix_case') {
        // For mix case, clear strain selection and weight
        newItems[index].strain_id = '';
        newItems[index].weight = '';
      } else if (value === 'weight') {
        // For weight format, clear case quantity
        newItems[index].case_quantity = '';
      } else if (value === 'retail_case_600') {
        // For 600g retail case, set strain to Lion's Mane
        const lionsManeStrain = Array.from(strains).find(strain => 
          strain.strain_name === "Lion's Mane"
        );
        if (lionsManeStrain) {
          newItems[index].strain_id = lionsManeStrain.strain_id;
        }
        newItems[index].weight = '';
      } else {
        // For other case formats, clear weight
        newItems[index].weight = '';
      }
    }

    setOrderItems(newItems);
  };

  const handleAddEditedOrderItem = () => {
    setEditedOrderItems([...editedOrderItems, { strain_id: '', weight: '' }]);
  };

  const handleRemoveEditedOrderItem = (index) => {
    if (editedOrderItems.length > 1) {
      setEditedOrderItems(editedOrderItems.filter((_, i) => i !== index));
    }
  };

  const handleEditedOrderItemChange = (index, field, value) => {
    const newItems = [...editedOrderItems];
    newItems[index][field] = value;
    setEditedOrderItems(newItems);
  };

  // Helper function to get week number
  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNumber;
  };

  // Helper function to get month name
  const getMonthName = (date) => {
    return new Date(date).toLocaleString('default', { month: 'long' });
  };

  // Get week start and end dates
  const getWeekDates = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day;
    const weekStart = new Date(d.setDate(diff));
    const weekEnd = new Date(d.setDate(diff + 6));
    return { weekStart, weekEnd };
  };

  // Update the week display in the table header section
  const formatDateRange = (date) => {
    const { weekStart, weekEnd } = getWeekDates(date);
    const weekNumber = getWeekNumber(date);
    const monthName = getMonthName(date);
    const year = date.getFullYear();

    return `Week ${weekNumber} - ${monthName} ${year} (${weekStart.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    })} - ${weekEnd.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    })})`;
  };

  const handleOpenShrinkageDialog = () => {
    // Make sure we have the selected farm
    if (!selectedFarm) {
      alert('Please select a farm first');
      return;
    }

    // Initialize shrinkage data from current inventory for all strains
    const shrinkageItems = Array.from(strains)
      .map(strain => {
        const currentWeight = processedData
          .find(row => row.farm_id === selectedFarm)
          ?.strains[strain.strain_name] || 0;
        
        return {
          strain_id: strain.strain_id,
          strain_name: strain.strain_name,
          current_weight: parseFloat(currentWeight).toFixed(2),
          new_weight: ''
        };
      });

    console.log('Initialized shrinkage data:', shrinkageItems);
    setShrinkageData(shrinkageItems);
    setOpenShrinkageDialog(true);
  };

  const handleCloseShrinkageDialog = () => {
    setOpenShrinkageDialog(false);
    setShrinkageData([]);
  };

  const handleShrinkageWeightChange = (strainId, value) => {
    setShrinkageData(prev => prev.map(item => {
      if (item.strain_id === strainId) {
        // Ensure the value is a valid number
        const newWeight = value === '' ? '' : parseFloat(value);
        return { ...item, new_weight: newWeight };
      }
      return item;
    }));
  };

  const handleSaveShrinkage = async () => {
    try {
      // Add logging to see the raw data
      console.log('Raw shrinkage data:', shrinkageData);

      const updates = shrinkageData
        .filter(item => {
          const currentWeight = parseFloat(item.current_weight);
          const newWeight = parseFloat(item.new_weight);
          
          console.log('Checking item:', {
            strain: item.strain_name,
            currentWeight,
            newWeight,
            isValid: item.new_weight !== '' && newWeight < currentWeight
          });
          
          return item.new_weight !== '' && newWeight < currentWeight;
        })
        .map(item => ({
          farm_id: Number(selectedFarm),
          strain_id: Number(item.strain_id),
          current_weight: parseFloat(item.current_weight),
          new_weight: parseFloat(item.new_weight)
        }));

      console.log('Filtered updates:', updates);

      if (updates.length === 0) {
        console.log('No valid updates to send');
        handleCloseShrinkageDialog();
        return;
      }

      const response = await fetch(`${BASE_URL}/api/inventory/shrinkage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ updates })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server response:', errorData);
        throw new Error(errorData.message || 'Failed to record shrinkage');
      }

      await fetchInventoryData();
      handleCloseShrinkageDialog();
    } catch (error) {
      console.error('Error recording shrinkage:', error);
      alert('Failed to record shrinkage: ' + error.message);
    }
  };

  // Add this helper function at the top with other functions
  const formatDateWithDay = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long', // 'short' gives "Mon", "Tue", etc.
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  };

  const handleItemCompletion = async (orderId, itemIndex, checked) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/orders/items/${orderId}/${itemIndex}/toggle-completion`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ isCompleted: checked ? 1 : 0 })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update item completion status');
      }

      // Refresh orders after updating
      fetchOrders();
    } catch (error) {
      console.error('Error updating item completion:', error);
    }
  };

  const goToPreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  const goToNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  const goToCurrentWeek = () => {
    setCurrentDate(new Date());
  };

  const handleSendToOrderEase = async (order) => {
    setLoadingOrderId(order.order_id);
    try {
      const response = await fetch(`${BASE_URL}/api/orderease/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          customerName: order.customer_name
        })
      });

      const data = await response.json();
      
      const itemsWithQuantities = data.products.map(product => {
        const orderItem = order.items.find(item => {
          const desc = product.description;
          const mappedStrainName = STRAIN_NAME_MAPPING[item.strain_name] || item.strain_name;
          const strainWithMushrooms = `${mappedStrainName} Mushrooms`;
          
          const cleanString = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
          const cleanDesc = cleanString(desc);
          const cleanStrainWithMushrooms = cleanString(strainWithMushrooms);
          
          console.log('Comparing:', {
            orderFormat: item.order_format,
            originalStrain: item.strain_name,
            mappedStrain: mappedStrainName,
            orderEaseDesc: desc,
            cleanDesc,
            cleanStrainWithMushrooms
          });

          if (item.order_format === 'retail_case') {
            return cleanDesc === cleanString(`Retail - ${strainWithMushrooms} (400 grams)`);
          }
          if (item.order_format === 'retail_case_100') {
            return cleanDesc === cleanString(`Retail - ${strainWithMushrooms} (100 grams)`);
          }
          if (item.order_format === 'retail_case_600') {
            return cleanDesc === cleanString(`Retail - ${strainWithMushrooms} (150 grams)`);
          }
          if (item.order_format === 'wholesale_case') {
            return cleanDesc === cleanString(`Case - ${strainWithMushrooms}`);
          }
          if (item.order_format === 'weight') {
            return cleanDesc === cleanStrainWithMushrooms;
          }
          if (item.order_format === 'mix_case') {
            return desc.toLowerCase().includes('mixed case');
          }
          return false;
        });

        const productWithOrder = {
          ...product,
          quantity: 0,
          originalOrder: order
        };

        if (orderItem) {
          switch (orderItem.order_format) {
            case 'retail_case':
            case 'retail_case_100':
            case 'retail_case_600':
            case 'wholesale_case':
            case 'mix_case':
              productWithOrder.quantity = orderItem.case_quantity;
              break;
            case 'weight':
              productWithOrder.quantity = parseFloat(orderItem.weight);
              break;
            default:
              productWithOrder.quantity = 0;
          }
        }

        return productWithOrder;
      });

      console.log('Final items with quantities and original order:', itemsWithQuantities);

      setOrderEaseProducts(itemsWithQuantities);
      setOpenOrderEaseDialog(true);
      setSelectedOrderEaseItems(itemsWithQuantities.filter(item => item.quantity > 0));

    } catch (error) {
      console.error('Error sending order to OrderEase:', error);
      alert('Failed to send order to OrderEase: ' + error.message);
    } finally {
      setLoadingOrderId(null);
    }
  };

  const handleSubmitToOrderEase = async (selectedItems, poNumber = '') => {
    try {
      // Get the original order from the first selected item
      const originalOrder = selectedItems[0]?.originalOrder;
      
      if (!originalOrder) {
        throw new Error('Order information is missing');
      }

      console.log('Original Order:', originalOrder);

      const orderData = {
        customerName: originalOrder.customer_name,
        orderId: originalOrder.order_id,
        items: selectedItems.map(item => ({
          sku: item.sku,
          quantity: item.quantity,
          pricingLevelId: item.pricingLevelId,
          originalPrice: item.price,
          discountPrice: item.discountPrice
        })),
        poNumber: poNumber || undefined
      };

      const response = await fetch(`${BASE_URL}/api/orderease/place-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(orderData)
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to submit order to OrderEase');
      }

      const result = await response.json();
      console.log('OrderEase submission result:', result);
      
      await fetchOrders();  // Refresh orders to show updated OrderEase status
      
      setOpenOrderEaseDialog(false);
      setSelectedOrderEaseItems([]);
      setPoNumber('');
      alert('Order successfully submitted to OrderEase');

    } catch (error) {
      console.error('Error submitting to OrderEase:', error);
      alert('Failed to submit order to OrderEase: ' + error.message);
    }
  };

  const isOrderValid = () => {
    return (
      selectedCustomer &&
      selectedFarm &&
      orderItems.every(item => {
        if (item.order_format === 'mix_case') {
          // For mix case, only case_quantity is required
          return item.case_quantity && parseInt(item.case_quantity) > 0;
        }
        
        // For other formats, need strain_id and either weight or case_quantity
        if (!item.strain_id) return false;
        
        switch (item.order_format) {
          case 'weight':
            return item.weight && parseFloat(item.weight) > 0;
          case 'wholesale_case':
          case 'retail_case':
            return item.case_quantity && parseInt(item.case_quantity) > 0;
          default:
            return false;
        }
      })
    );
  };

  const formatOrderItem = (item) => {
    if (item.order_format === 'mix_case') {
      return `Mix Case (Qty: ${parseInt(item.case_quantity) || 0})`;
    } else if (item.order_format === 'wholesale_case') {
      return `${item.strain_name} - ${item.case_quantity} case(s) (${item.case_quantity * 2} lbs)`;
    } else if (item.order_format === 'retail_case' || item.order_format === 'retail_case_100') {
      return `${item.strain_name} - ${item.case_quantity} case(s) (${(item.case_quantity * 0.88).toFixed(2)} lbs)`;
    } else if (item.order_format === 'retail_case_600') {
      return `${item.strain_name} - ${item.case_quantity} case(s) (${(item.case_quantity * 1.32).toFixed(2)} lbs)`;
    }
    return `${item.strain_name} - ${parseFloat(item.weight).toFixed(2)} lbs`;
  };

  // Update handleUpdateOrder function to include po_url
  const handleUpdateOrder = async () => {
    try {
      const validOrderItems = orderItems.filter(item => {
        if (item.order_format === 'mix_case') {
          return item.case_quantity && parseInt(item.case_quantity) > 0;
        }
        if (item.order_format === 'weight') {
          return item.strain_id && item.weight && parseFloat(item.weight) > 0;
        }
        return item.strain_id && item.case_quantity && parseInt(item.case_quantity) > 0;
      });

      if (validOrderItems.length === 0) {
        throw new Error('Please add at least one valid order item');
      }

      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        return d.toISOString().slice(0, 19).replace('T', ' ');
      };

      const orderData = {
        customer_id: selectedCustomer.customer_id,
        farm_id: selectedOrderFarm,
        order_items: validOrderItems.map(item => ({
          strain_id: item.strain_id ? parseInt(item.strain_id) : null,
          order_format: item.order_format,
          case_quantity: item.case_quantity ? parseInt(item.case_quantity) : null,
          weight: item.weight ? parseFloat(item.weight) : null
        })),
        order_date: formatDate(new Date()),
        fulfillment_date: fulfillmentDate ? formatDate(fulfillmentDate) : null,
        po_url: poUrl || null  // Include po_url in the update data
      };

      console.log('Updating order with data:', orderData); // Debug log

      const response = await fetch(`${BASE_URL}/api/orders/${selectedOrder.order_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify(orderData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update order');
      }

      await Promise.all([fetchOrders(), fetchInventoryData()]);
      handleCloseOrderDialog();
    } catch (error) {
      console.error('Error updating order:', error);
      alert('Failed to update order: ' + error.message);
    }
  };

  const handleDownloadOrderEasePDF = async (order) => {
    try {
      // If there's an invoice URL, open it in a new tab
      if (order.invoice_url) {
        window.open(order.invoice_url, '_blank');
        return;
      }

      // Otherwise download through the API
      const response = await fetch(`${BASE_URL}/api/orderease/invoice/${order.order_id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download invoice');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `OrderEase_${order.order_id}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading invoice:', error);
      alert('Failed to download invoice: ' + error.message);
    }
  };

  // Update the calculateDailyTotals function
  const calculateDailyTotals = () => {
    const dailyTotals = {};
    
    // Get today's date in local format (YYYY-MM-DD)
    const today = new Date().toLocaleDateString();
    
    weeklyOrders
      .filter(order => {
        // Only include orders from selected farm and scheduled for today
        return order.farm_id === selectedFarm && 
               order.fulfillment_date && 
               new Date(order.fulfillment_date).toLocaleDateString() === today;
      })
      .forEach(order => {
        order.items.forEach(item => {
          // Skip mix cases as they don't have specific strains
          if (item.order_format === 'mix_case') return;

          const strainName = item.strain_name;
          if (!dailyTotals[strainName]) {
            dailyTotals[strainName] = {
              weight_format: 0, // Weight orders (in lbs)
              wholesale_cases: 0, // Number of wholesale cases
              retail_cases_400: 0, // Number of 400g retail cases
              retail_cases_100: 0, // Number of 100g retail cases (displayed as 100g but same weight as 400g)
              retail_cases_600: 0, // Number of 600g retail cases
              total_weight: 0 // Combined total weight
            };
          }

          // Add to totals based on order format
          switch (item.order_format) {
            case 'weight':
              const weightAmount = parseFloat(item.weight || 0);
              dailyTotals[strainName].weight_format += weightAmount;
              dailyTotals[strainName].total_weight += weightAmount;
              break;
            case 'wholesale_case':
              const wholesaleCases = parseInt(item.case_quantity || 0);
              dailyTotals[strainName].wholesale_cases += wholesaleCases;
              dailyTotals[strainName].total_weight += wholesaleCases * 2; // 2 lbs per case
              break;
            case 'retail_case':
              const retailCases = parseInt(item.case_quantity || 0);
              dailyTotals[strainName].retail_cases_400 += retailCases;
              dailyTotals[strainName].total_weight += retailCases * 0.88; // 400g = 0.88 lbs per case
              break;
            case 'retail_case_100':
              const retail100Cases = parseInt(item.case_quantity || 0);
              dailyTotals[strainName].retail_cases_100 += retail100Cases;
              dailyTotals[strainName].total_weight += retail100Cases * 0.88; // Same weight as 400g
              break;
            case 'retail_case_600':
              const retail600Cases = parseInt(item.case_quantity || 0);
              dailyTotals[strainName].retail_cases_600 += retail600Cases;
              dailyTotals[strainName].total_weight += retail600Cases * 1.32; // 600g = 1.32 lbs per case
              break;
          }
        });
      });

    return dailyTotals;
  };

  const handleCreateNewCustomer = () => {
    handleCloseOrderDialog();
    navigate('/managecustomers');
  };

  return (
    <Box sx={{ p: 3, mt: 8 }}>
      <Stack spacing={4}>
        {/* Inventory Section */}
        <Box>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TableContainer 
                component={Paper} 
                sx={{ 
                  maxWidth: 'fit-content',
                  '& .MuiTable-root': {
                    borderSpacing: '0px',
                    borderCollapse: 'separate'
                  }
                }}
              >
                <Table 
                  aria-label="inventory table" 
                  size="small" 
                  sx={{ 
                    '& .MuiTableCell-root': {
                      padding: '8px 8px',
                      borderSpacing: 0,
                      width: 'auto',
                      minWidth: '80px',
                      borderRight: '1px solid rgba(224, 224, 224, 0.4)',
                      '&:last-child': {
                        borderRight: 'none'
                      }
                    },
                    '& .MuiTableCell-head': {
                      backgroundColor: '#fff'
                    },
                    '& .MuiInputBase-input': {
                      padding: '4px 8px',
                      height: '20px'
                    },
                    '& .MuiOutlinedInput-root': {
                      height: '28px'
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: '160px' }}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Farm</InputLabel>
                          <Select
                            value={selectedFarm}
                            label="Farm"
                            onChange={handleInventoryFarmChange}
                          >
                            {farms.map((farm) => (
                              <MenuItem key={farm.farm_id} value={farm.farm_id}>
                                {farm.farm_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      {Array.from(strains).map(strain => (
                        <TableCell 
                          key={strain.strain_id} 
                          align="right" 
                          sx={{ 
                            fontWeight: 'bold',
                            minWidth: '80px'
                          }}
                        >
                          {strain.strain_name}
                        </TableCell>
                      ))}
                      <TableCell sx={{ width: '50px' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processedData.map((row) => (
                      <TableRow 
                        key={row.farm_id}
                        sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}
                      >
                        <TableCell>{row.farm_name}</TableCell>
                        {Array.from(strains).map(strain => (
                          <TableCell 
                            key={strain.strain_id} 
                            align="right"
                            sx={{ 
                              padding: '4px 8px',
                              height: '32px'
                            }}
                          >
                            {editingRow === row.farm_id ? (
                              <TextField
                                size="small"
                                type="number"
                                value={editedData[strain.strain_id] || ''}
                                onChange={(e) => handleWeightChange(strain, e.target.value)}
                                inputProps={{ 
                                  style: { 
                                    textAlign: 'right', 
                                    padding: '4px',
                                    height: '20px'
                                  },
                                  min: 0,
                                  step: 0.01
                                }}
                                sx={{ 
                                  width: '70px',
                                  '& .MuiOutlinedInput-root': {
                                    height: '28px'
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    padding: '4px 8px'
                                  },
                                  '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.12)'
                                  }
                                }}
                              />
                            ) : (
                              <span style={{ 
                                display: 'inline-block', 
                                minWidth: '70px', 
                                textAlign: 'right',
                                color: row.strains[strain.strain_name] < 0 ? '#ff6b6b' : 'inherit'  // Add soft red color for negative values
                              }}>
                                {row.strains[strain.strain_name] 
                                  ? parseFloat(row.strains[strain.strain_name]).toFixed(2) 
                                  : '0.00'
                              }
                              </span>
                            )}
                          </TableCell>
                        ))}
                        <TableCell 
                          align="right"
                          sx={{ 
                            padding: '4px',
                            width: '70px'
                          }}
                        >
                          {editingRow === row.farm_id ? (
                            <>
                              {hasChanges && (
                                <IconButton 
                                  color="primary" 
                                  onClick={() => handleSave(row.farm_id)}
                                  size="small"
                                  sx={{ 
                                    padding: '4px',
                                    marginRight: '4px'
                                  }}
                                >
                                  <SaveIcon sx={{ fontSize: '1.2rem' }} />
                                </IconButton>
                              )}
                              <IconButton 
                                color="error" 
                                onClick={handleCancelEdit}
                                size="small"
                                sx={{ padding: '4px' }}
                              >
                                <CloseIcon sx={{ fontSize: '1.2rem' }} />
                              </IconButton>
                            </>
                          ) : (
                            <IconButton 
                              onClick={() => handleEdit(row.farm_id)}
                              size="small"
                              sx={{ padding: '4px' }}
                            >
                              <EditIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

        {/* Add Record Shrinkage Button */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={handleOpenShrinkageDialog}
          >
            Record Shrinkage
          </Button>
        </Box>

        {/* Orders Section */}
        <Box>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <Box sx={{ mb: 3 }}>
                {/* Week Navigation with Add Order Button */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    mb: 3,
                    gap: 2
                  }}
                >
                  <IconButton onClick={goToPreviousWeek}>
                    <NavigateBeforeIcon />
                  </IconButton>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Typography variant="h6">
                      {formatDateRange(currentDate)}
                    </Typography>
                    <IconButton 
                      size="small" 
                      onClick={goToCurrentWeek}
                      title="Go to current week"
                    >
                      <TodayIcon />
                    </IconButton>
                  </Box>

                  <IconButton onClick={goToNextWeek}>
                    <NavigateNextIcon />
                  </IconButton>

                  {/* Add Order Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setOpenOrderDialog(true)}
                    sx={{ ml: 2 }}
                  >
                    New Order
                  </Button>
                </Box>

                {/* Weekly Orders Table */}
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Fulfillment Date</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Farm</TableCell>
                        <TableCell>Order Items</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {weeklyOrders
                        .filter(order => order.farm_id === selectedFarm) // Filter orders by selected farm
                        .sort((a, b) => {
                          // Handle null fulfillment dates
                          if (!a.fulfillment_date && !b.fulfillment_date) return 0;
                          if (!a.fulfillment_date) return 1;  // Null dates go to the end
                          if (!b.fulfillment_date) return -1;
                          
                          // Compare fulfillment dates
                          return new Date(a.fulfillment_date) - new Date(b.fulfillment_date);
                        })
                        .map((order) => (
                          <TableRow key={order.order_id}>
                            <TableCell>{formatDateWithDay(order.fulfillment_date)}</TableCell>
                            <TableCell>{order.customer_name}</TableCell>
                            <TableCell>{order.farm_name}</TableCell>
                            <TableCell>
                              <Stack spacing={0.5}>
                                {(typeof order.items === 'string' ? JSON.parse(order.items) : order.items).map((item, index) => (
                                  <Box 
                                    key={index}
                                    sx={{ 
                                      display: 'flex', 
                                      gap: 1,
                                      alignItems: 'center',
                                      '&:not(:last-child)': {
                                        borderBottom: '1px solid',
                                        borderColor: 'divider',
                                        pb: 0.5
                                      }
                                    }}
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={Boolean(item.is_completed)}
                                      onChange={(e) => handleItemCompletion(order.order_id, index, e.target.checked)}
                                      sx={{ p: 0.5 }}
                                    />
                                    <Typography 
                                      variant="body2" 
                                      sx={{ 
                                        flexGrow: 1,
                                        textDecoration: item.is_completed ? 'line-through' : 'none',
                                        color: item.is_completed ? 'text.secondary' : 'text.primary'
                                      }}
                                    >
                                      {formatOrderItem(item)}
                                    </Typography>
                                  </Box>
                                ))}
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                                <IconButton 
                                  size="small"
                                  onClick={() => handleEditOrder(order)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton 
                                  size="small"
                                  color="error"
                                  onClick={() => handleDeleteOrder(order.order_id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <Tooltip title="Review and Confirm OrderEase Order">
                                  <span>
                                    <IconButton
                                      onClick={() => handleSendToOrderEase(order)}
                                      disabled={loadingOrderId !== null}
                                      size="small"
                                    >
                                      {loadingOrderId === order.order_id ? (
                                        <CircularProgress size={24} />
                                      ) : (
                                        <SendIcon fontSize="small" />
                                      )}
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip title="Download OrderEase PDF">
                                  <span>
                                    <IconButton
                                      onClick={() => handleDownloadOrderEasePDF(order)}
                                      disabled={!order.invoice_url && !order.orderease_id}
                                      size="small"
                                    >
                                      <DownloadIcon fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>                          
                </TableContainer>

                {weeklyOrders.filter(order => order.farm_id === selectedFarm).length === 0 && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <Typography color="text.secondary">
                      No orders for this farm this week
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Daily Totals Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Today's Totals</Typography>
          <Paper sx={{ p: 2 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Strain</TableCell>
                    <TableCell align="right">Weight Format (lbs)</TableCell>
                    <TableCell align="right">Wholesale Cases</TableCell>
                    <TableCell align="right">Retail (400g)</TableCell>
                    <TableCell align="right">Retail (100g)</TableCell>
                    <TableCell align="right">Lion's Mane (150g)</TableCell>
                    <TableCell align="right">Total Weight (lbs)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(calculateDailyTotals()).map(([strainName, totals]) => (
                    <TableRow key={strainName}>
                      <TableCell>{strainName}</TableCell>
                      <TableCell align="right">
                        {totals.weight_format.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {totals.wholesale_cases}
                      </TableCell>
                      <TableCell align="right">
                        {totals.retail_cases_400}
                      </TableCell>
                      <TableCell align="right">
                        {totals.retail_cases_100}
                      </TableCell>
                      <TableCell align="right">
                        {totals.retail_cases_600}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {totals.total_weight.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {Object.keys(calculateDailyTotals()).length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                        <Typography color="text.secondary">
                          No orders scheduled for today
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>

        {/* Order Dialog */}
        <Dialog 
          open={openOrderDialog} 
          onClose={handleCloseOrderDialog} 
          maxWidth="md" 
          fullWidth
        >
          <DialogTitle>
            {editMode ? 'Update Order' : 'Create New Order'}
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3} sx={{ mt: 2 }}>
              <Autocomplete
                size="small"
                options={customers}
                value={selectedCustomer}
                onChange={(event, newValue) => {
                  setSelectedCustomer(newValue);
                }}
                getOptionLabel={(option) => option?.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer"
                    placeholder="Search customer..."
                  />
                )}
                isOptionEqualToValue={(option, value) => 
                  option?.customer_id === value?.customer_id
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body2">{option.name}</Typography>
                  </li>
                )}
                noOptionsText={
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between',
                    p: 1
                  }}>
                    <Typography variant="body2">No customers found</Typography>
                    <Button
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleCreateNewCustomer}
                    >
                      Create New Customer
                    </Button>
                  </Box>
                }
              />

              {/* Order Farm Selection */}
              <FormControl fullWidth size="small">
                <InputLabel>Farm</InputLabel>
                <Select
                  value={selectedOrderFarm}
                  label="Farm"
                  onChange={(e) => setSelectedOrderFarm(e.target.value)}
                >
                  {farms.map((farm) => (
                    <MenuItem key={farm.farm_id} value={farm.farm_id}>
                      {farm.farm_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Add PO URL field */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="PO URL (Optional)"
                  value={poUrl}
                  onChange={(e) => setPoUrl(e.target.value)}
                  placeholder="https://example.com/po-document"
                />
              </Grid>

              {/* Order Items Section */}
              <Box sx={{ border: 1, borderColor: 'divider', p: 2, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1">Order Items</Typography>
                  <Button
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={handleAddOrderItem}
                  >
                    Add Item
                  </Button>
                </Box>
                
                {orderItems.map((item, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      display: 'flex', 
                      gap: 2, 
                      mb: 2, 
                      alignItems: 'center'
                    }}
                  >
                    <FormControl size="small" sx={{ width: 200 }}>
                      <InputLabel>Format</InputLabel>
                      <Select
                        value={item.order_format || 'weight'}
                        onChange={(e) => handleOrderItemChange(index, 'order_format', e.target.value)}
                        label="Format"
                      >
                        {ORDER_FORMATS.map((format) => (
                          <MenuItem key={format.value} value={format.value}>
                            {format.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {item.order_format !== 'mix_case' && (
                      <FormControl size="small" sx={{ flexGrow: 1 }}>
                        <InputLabel>Strain</InputLabel>
                        <Select
                          value={item.strain_id || ''}
                          onChange={(e) => handleOrderItemChange(index, 'strain_id', e.target.value)}
                          label="Strain"
                          disabled={item.order_format === 'mix_case' || item.order_format === 'retail_case_600'}
                        >
                          {Array.from(strains).map((strain) => (
                            <MenuItem 
                              key={strain.strain_id} 
                              value={strain.strain_id}
                              disabled={item.order_format === 'retail_case_600' && strain.strain_name !== "Lion's Mane"}
                            >
                              {strain.strain_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {item.order_format === 'weight' ? (
                      <TextField
                        size="small"
                        label="Weight (lbs)"
                        type="number"
                        value={item.weight || ''}
                        onChange={(e) => handleOrderItemChange(index, 'weight', parseFloat(e.target.value))}
                        inputProps={{ min: 0, step: 0.01 }}
                        sx={{ width: '150px' }}
                      />
                    ) : (
                      <TextField
                        size="small"
                        label="Quantity"
                        type="number"
                        value={item.case_quantity || ''}
                        onChange={(e) => handleOrderItemChange(index, 'case_quantity', parseInt(e.target.value))}
                        inputProps={{ min: 1, step: 1 }}
                        sx={{ width: '120px' }}
                      />
                    )}

                    <IconButton 
                      size="small"
                      onClick={() => handleRemoveOrderItem(index)}
                      disabled={orderItems.length === 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>

              {/* Date Selection - only fulfillment date */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fulfillment Date"
                  value={fulfillmentDate}
                  onChange={(newValue) => {
                    console.log('New fulfillment date:', newValue); // Debug log
                    setFulfillmentDate(newValue);
                  }}
                  slotProps={{ 
                    textField: { 
                      size: "small",
                      fullWidth: true,
                      error: false
                    } 
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOrderDialog}>Cancel</Button>
            <Button 
              onClick={editMode ? handleUpdateOrder : handleCreateOrder}
              variant="contained" 
              color="primary"
              disabled={!selectedCustomer || !selectedOrderFarm || !orderItems.some(item => 
                (item.order_format === 'weight' && item.weight) || 
                (item.order_format !== 'weight' && item.case_quantity)
              )}
            >
              {editMode ? 'Update Order' : 'Create Order'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Shrinkage Dialog */}
        <Dialog 
          open={openShrinkageDialog} 
          onClose={handleCloseShrinkageDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Record Shrinkage</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Strain</TableCell>
                    <TableCell align="right">Current Weight</TableCell>
                    <TableCell align="right">New Weight</TableCell>
                    <TableCell align="right">Shrinkage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shrinkageData.map((item) => {
                    const shrinkage = item.new_weight 
                      ? (parseFloat(item.current_weight) - parseFloat(item.new_weight)).toFixed(2)
                      : '0.00';
                    const isNegativeShrinkage = item.new_weight && parseFloat(item.new_weight) > parseFloat(item.current_weight);

                    return (
                      <TableRow key={item.strain_id}>
                        <TableCell>{item.strain_name}</TableCell>
                        <TableCell align="right">
                          {parseFloat(item.current_weight).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            size="small"
                            value={item.new_weight}
                            onChange={(e) => handleShrinkageWeightChange(item.strain_id, e.target.value)}
                            error={isNegativeShrinkage}
                            inputProps={{ 
                              step: 0.01,
                              min: 0,
                              style: { textAlign: 'right' }
                            }}
                            sx={{ width: '100px' }}
                          />
                        </TableCell>
                        <TableCell 
                          align="right"
                          sx={{ 
                            color: parseFloat(shrinkage) > 0 ? 'error.main' : 'inherit',
                            fontWeight: parseFloat(shrinkage) > 0 ? 'bold' : 'normal'
                          }}
                        >
                          {shrinkage}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseShrinkageDialog}>Cancel</Button>
            <Button 
              onClick={handleSaveShrinkage} 
              color="primary"
              disabled={!shrinkageData.some(item => 
                item.new_weight && 
                parseFloat(item.new_weight) < parseFloat(item.current_weight)
              )}
            >
              Record Shrinkage
            </Button>
          </DialogActions>
        </Dialog>

        {/* OrderEase Dialog */}
        <Dialog 
          open={openOrderEaseDialog} 
          onClose={() => {
            setOpenOrderEaseDialog(false);
            setSelectedOrderEaseItems([]);
            setPoNumber('');
          }}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">OrderEase Products</Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mb: 3, mt: 1 }}>
              <TextField
                label="PO Number"
                value={poNumber}
                onChange={(e) => setPoNumber(e.target.value)}
                size="small"
                fullWidth
                placeholder="Enter PO Number"
                sx={{ mt: 2 }}
              />
            </Box>

            {orderEaseProducts && orderEaseProducts.length > 0 ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Pricing Level</TableCell>
                      <TableCell align="right">Original Price</TableCell>
                      <TableCell align="right">Discount</TableCell>
                      <TableCell align="right">Final Price</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderEaseProducts
                      .slice()
                      .sort((a, b) => b.quantity - a.quantity)
                      .map((product, index) => {
                        const selectedItem = selectedOrderEaseItems.find(item => 
                          item.sku === product.sku && 
                          item.pricingLevelId === product.pricingLevelId
                        );
                        
                        const discount = selectedItem?.discountPrice ?? 0;
                        const finalPrice = product.price - discount;

                        return (
                          <TableRow 
                            key={`${product.sku}-${index}`}
                            sx={{
                              backgroundColor: selectedItem?.quantity > 0 ? '#e3f2fd' : 'inherit',
                              transition: 'background-color 0.2s ease'  // Add smooth transition
                            }}
                          >
                            <TableCell>{product.sku}</TableCell>
                            <TableCell>{product.description}</TableCell>
                            <TableCell>{product.pricingLevelName}</TableCell>
                            <TableCell align="right">
                              ${Number(product.price).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                type="number"
                                size="small"
                                InputProps={{ 
                                  inputProps: { 
                                    min: 0, 
                                    step: 0.01,
                                    style: { textAlign: 'right' }
                                  } 
                                }}
                                sx={{ width: 100 }}
                                value={discount || ''}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  // Remove leading zeros and handle empty string
                                  const cleanValue = inputValue.replace(/^0+/, '') || '0';
                                  const numericValue = parseFloat(cleanValue);

                                  setSelectedOrderEaseItems(prev => {
                                    const existingItem = prev.find(item => 
                                      item.sku === product.sku && 
                                      item.pricingLevelId === product.pricingLevelId
                                    );

                                    const newItems = prev.filter(item => 
                                      !(item.sku === product.sku && 
                                        item.pricingLevelId === product.pricingLevelId)
                                    );

                                    newItems.push({
                                      ...product,
                                      quantity: existingItem?.quantity || 0,
                                      discountPrice: isNaN(numericValue) ? 0 : numericValue
                                    });

                                    return newItems;
                                  });
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              ${finalPrice.toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                type="number"
                                size="small"
                                InputProps={{ 
                                  inputProps: { 
                                    min: 0, 
                                    step: 0.1,
                                    style: { textAlign: 'right' }
                                  } 
                                }}
                                sx={{ width: 100 }}
                                value={selectedItem?.quantity || ''}
                                onChange={(e) => {
                                  const value = e.target.value === '' ? 0 : parseFloat(e.target.value);
                                  if (isNaN(value)) return;

                                  setSelectedOrderEaseItems(prev => {
                                    const existingItem = prev.find(item => 
                                      item.sku === product.sku && 
                                      item.pricingLevelId === product.pricingLevelId
                                    );

                                    const newItems = prev.filter(item => 
                                      !(item.sku === product.sku && 
                                        item.pricingLevelId === product.pricingLevelId)
                                    );

                                    newItems.push({
                                      ...product,
                                      quantity: value,
                                      discountPrice: existingItem?.discountPrice || 0  // Maintain existing discount
                                    });

                                    return newItems;
                                  });
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box sx={{ py: 3, textAlign: 'center' }}>
                <Typography color="text.secondary">
                  No products available for this customer
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Stack spacing={1}>
                {poNumber && (
                  <Typography variant="subtitle2">
                    PO Number: {poNumber}
                  </Typography>
                )}
                {selectedOrderEaseItems.some(item => item.quantity > 0) && (
                  <>
                    <Typography variant="subtitle2">
                      Total Items: {selectedOrderEaseItems.filter(item => item.quantity > 0).length}
                    </Typography>
                    <Typography variant="subtitle2">
                      Original Total: ${selectedOrderEaseItems
                        .filter(item => item.quantity > 0)
                        .reduce((sum, item) => sum + (item.price * item.quantity), 0)
                        .toFixed(2)}
                    </Typography>
                    <Typography variant="subtitle2" color="primary">
                      Final Total: ${selectedOrderEaseItems
                        .filter(item => item.quantity > 0)
                        .reduce((sum, item) => sum + ((item.price - (item.discountPrice || 0)) * item.quantity), 0)
                        .toFixed(2)}
                    </Typography>
                  </>
                )}
              </Stack>
            </Box>
            <Button onClick={() => {
              setOpenOrderEaseDialog(false);
              setSelectedOrderEaseItems([]);
              setPoNumber('');
            }}>
              Cancel
            </Button>
            <Button 
              variant="contained" 
              color="primary"
              disabled={!selectedOrderEaseItems.some(item => item.quantity > 0)}
              onClick={() => handleSubmitToOrderEase(selectedOrderEaseItems, poNumber)}
            >
              Submit Order
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
}

export default CustomerOrders; 