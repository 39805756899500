import React, { useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ScaleIcon from '@mui/icons-material/Scale';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography'; 
import { Link } from 'react-router-dom';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';
import { getUserId } from '../../auth/auth.js';
import InventoryIcon from '@mui/icons-material/Inventory';
import Divider from '@mui/material/Divider';

export const mainListItems = ({ handleClick, openDashboard, openShipments, openReceived, openCut, openYield, openSDW, navBarOpen }) => (
  <React.Fragment>
    {/* Dashboard */}
    <ListItemButton component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>

    <Divider sx={{ my: 1 }} />

    {/* Block Orders Section */}
    <ListSubheader component="div" inset>
      Block Orders
    </ListSubheader>

    {/* <ListItemButton component={Link} to="/customerorders">
      <ListItemIcon>
        <InventoryIcon />
      </ListItemIcon>
      <ListItemText primary="Available Inventory" />
    </ListItemButton> */}

    <ListItemButton component={Link} to="/blockorder">
      <ListItemIcon>
        <PostAddIcon />
      </ListItemIcon>
      <ListItemText primary="Request order" />
    </ListItemButton>

    <ListItemButton component={Link} to="/blockorderlist">
      <ListItemIcon>
        <ReceiptLongIcon />
      </ListItemIcon>
      <ListItemText primary="Order List" />
    </ListItemButton>

    {/* <ListItemButton component={Link} to="/inoculationschedule">
      <ListItemIcon>
        <CalendarMonthIcon />
      </ListItemIcon>
      <ListItemText primary="Inoculation schedule" />
    </ListItemButton> */}

    <Divider sx={{ my: 1 }} />

    {/* Shipments */}
    <ListItemButton onClick={() => handleClick('Shipments')}>
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      <ListItemText primary="Shipments" />
      {openShipments ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openShipments} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton component={Link} to="/addshipment" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <AddIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Add</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/shipmentview" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <ViewIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">View</Typography>} />}
        </ListItemButton>
      </List>
    </Collapse>

    {/* Received */}
    <ListItemButton onClick={() => handleClick('Received')}>
      <ListItemIcon>
        <CallReceivedIcon />
      </ListItemIcon>
      <ListItemText primary="Received" />
      {openReceived ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openReceived} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton component={Link} to="/addreceived" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <AddIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Add</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/receivedview" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <ViewIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">View</Typography>} />}
        </ListItemButton>
      </List>
    </Collapse> 

    {/* Cut */}
    {/* <ListItemButton onClick={() => handleClick('Cut')}>
      <ListItemIcon>
        <ContentCutIcon />
      </ListItemIcon>
      <ListItemText primary="Cut" />
      {openCut ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton> */}
    <Collapse in={openCut} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton component={Link} to="/addcut" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <AddIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Add</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/cutview" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <ViewIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">View</Typography>} />}
        </ListItemButton>
      </List>
    </Collapse>

    {/* Yield */}
    <ListItemButton onClick={() => handleClick('Yield')}>
      <ListItemIcon>
        <ScaleIcon />
      </ListItemIcon>
      <ListItemText primary="Yield" />
      {openYield ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openYield} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton component={Link} to="/addyield" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <AddIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Add</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/yieldview" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <ViewIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">View</Typography>} />}
        </ListItemButton>
      </List>
    </Collapse>

    {/* Waste */}
    <ListItemButton onClick={() => handleClick('SDW')}>
      <ListItemIcon>
        <CategoryIcon />
      </ListItemIcon>
      <ListItemText primary="Waste" />
      {openSDW ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openSDW} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton component={Link} to="/addsdw" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <AddIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Add fresh or tossed</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/sdwview" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <Rotate90DegreesCwIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Fresh waste</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/tossedview" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <FolderDeleteIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Tossed waste</Typography>} />}
        </ListItemButton>
      </List>
    </Collapse>

    {/* Customer Orders */}
    <Divider sx={{ my: 1 }} />

    <ListItemButton component={Link} to="/customerorders">
      <ListItemIcon>
        <InventoryIcon />
      </ListItemIcon>
      <ListItemText primary="Customer Orders" />
    </ListItemButton>

  </React.Fragment>
);

export const blockOrderListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Block Orders
    </ListSubheader>

    <ListItemButton component={Link} to="/blockorder">
      <ListItemIcon>
        <PostAddIcon />
      </ListItemIcon>
      <ListItemText primary="Request order" />
    </ListItemButton>

    <ListItemButton component={Link} to="/blockorderlist">
      <ListItemIcon>
        <ReceiptLongIcon />
      </ListItemIcon>
      <ListItemText primary="Order List" />
    </ListItemButton>

    <ListItemButton component={Link} to="/inoculationschedule">
      <ListItemIcon>
        <CalendarMonthIcon />
      </ListItemIcon>
      <ListItemText primary="Inoculation schedule" />
    </ListItemButton>
    
  </React.Fragment>
)

export const LmsListItems = () => {
  const [userId, setUserId] = useState(null);
  const allowedUserIds = [1, 2, 72, 88,64];

  useEffect(() => {
    const fetchUserId = async () => {
      const currentUserId = await getUserId();
      console.log('Current User ID:', currentUserId);
      setUserId(currentUserId);
    };

    fetchUserId();
  }, []);

  const isUserAllowed = allowedUserIds.some(id => 
    String(id) === String(userId)
  );

  // return (
  //   <React.Fragment>
  //     <ListSubheader component="div" inset>
  //       LMS
  //     </ListSubheader>

  //     <ListItemButton component={Link} to="/lmshome">
  //       <ListItemIcon>
  //         <SchoolIcon />
  //       </ListItemIcon>
  //       <ListItemText primary="Home" />
  //     </ListItemButton>

  //     {isUserAllowed && (
  //       <ListItemButton component={Link} to="/lmsactivitylog">
  //         <ListItemIcon>
  //           <ChecklistIcon />
  //         </ListItemIcon>
  //         <ListItemText primary="Activity Log" />
  //       </ListItemButton>
  //     )}

  //     {isUserAllowed && (
  //       <ListItemButton component={Link} to="/lmsaddcourse">
  //         <ListItemIcon>
  //           <AddIcon />
  //         </ListItemIcon>
  //         <ListItemText primary="New Course" />
  //       </ListItemButton>
  //     )}

  //     {isUserAllowed && (
  //       <ListItemButton component={Link} to="/lmsaddvideos">
  //         <ListItemIcon>
  //           <AddIcon />
  //         </ListItemIcon>
  //         <ListItemText primary="New Videos" />
  //       </ListItemButton>
  //     )}

  //   </React.Fragment>
  // );
};

export const manageListItems = (
  <React.Fragment>

    <ListSubheader component="div" inset>
      Manage
    </ListSubheader>

    <ListItemButton component={Link} to="/manageusers">
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>

    <ListItemButton component={Link} to="/managecustomers">
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItemButton>

    <ListItemButton component={Link} to="/manageregions">
      <ListItemIcon>
        <LocationOnIcon />
      </ListItemIcon>
      <ListItemText primary="Regions" />
    </ListItemButton>

    <ListItemButton component={Link} to="/managefarms">
      <ListItemIcon>
        <AgricultureIcon />
      </ListItemIcon>
      <ListItemText primary="Farms" />
    </ListItemButton>

    <ListItemButton component={Link} to="/managestrains">
      <ListItemIcon>
        <LocalFloristIcon />
      </ListItemIcon>
      <ListItemText primary="Strains" />
    </ListItemButton>

    <ListItemButton component={Link} to="/managesources">
      <ListItemIcon>
        <WarehouseIcon />
      </ListItemIcon>
      <ListItemText primary="Sources" />
    </ListItemButton>
    
  </React.Fragment>
);

export const ManageListItems = ({ handleClick, openManage, navBarOpen }) => (
  <React.Fragment>
    <ListItemButton onClick={() => handleClick('Manage')}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Manage" />
      {openManage ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openManage} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton component={Link} to="/manageusers" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <PersonIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Users</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/managecustomers" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <GroupIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Customers</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/manageregions" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <LocationOnIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Regions</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/managefarms" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <AgricultureIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Farms</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/managestrains" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <LocalFloristIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Strains</Typography>} />}
        </ListItemButton>
        <ListItemButton component={Link} to="/managesources" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <WarehouseIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
          </ListItemIcon>
          {navBarOpen && <ListItemText primary={<Typography variant="body2">Sources</Typography>} />}
        </ListItemButton>
      </List>
    </Collapse>
  </React.Fragment>
);

export const extraListItems = ({ hasChatAccess }) => (
  <React.Fragment>

    {hasChatAccess && (
      <ListItemButton component={Link} to="/chatai">
        <ListItemIcon>
          <ChatBubbleOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="ChatAI" />
      </ListItemButton>
    )}
    
  </React.Fragment>
);
